.networkerror-cover {
    opacity: 1;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
}

.networkerror-cover-close {
    opacity: 0;
    pointer-events: none;
}

.networkerror-cover-open {
    opacity: 1;
}

.networkerror-main {
    position: fixed;
    left: calc(50% - 185px);
    width: 350px;
    top: calc(50% - 252px);
    height: 505px;
    background: white;
    box-shadow: 0px 2px 5px black;
    text-align: center;
    padding: 12px;
    background: rgba(20, 20, 20);
    color: rgba(190, 190, 190);
    cursor: default;
    transition: top 0.2s ease-in-out;
    text-shadow: none;
}

.networkerror-cover-close .networkerror-main {
    top: calc(50% - 240px);
}

.networkerror-main-header {
    font-size: 24px;
    font-weight: 800;
}

.networkerror-main-cover {
    width: 300px;
    height: 300px;
    background: url(/public/NetworkError.png) no-repeat center center;
    background-size: cover;
    border-radius: 150px;
    margin: auto;
}

.networkerror-description {
    padding-top: 18px;
    padding-bottom: 18px;
    font-weight: 400;
    font-size: 18px;
}

.networkerror-button {
    width: 200px;
    line-height: 20px;
    padding: 12px;
    border-radius: 12px;
    margin: auto;
    background: rgb(220, 202, 176);
    font-size: 14px;
    font-family: Arial;
    color: rgb(30, 30, 30);
    margin-top: 6px;
    cursor: pointer;
    position: relative;
}

.networkerror-button:hover:before {
    content: " ";
    position: absolute;
    top: 0px; left: 0px; width: 100%; height: 100%;
    background: rgba(0, 0, 0, 0.1);
}

.networkerror-button:active:before {
    content: " ";
    position: absolute;
    top: 0px; left: 0px; width: 100%; height: 100%;
    background: rgba(0, 0, 0, 0.2);
}
.networkerror-cover {
    opacity: 1;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
}

.networkerror-cover-close {
    opacity: 0;
    pointer-events: none;
}

.networkerror-cover-open {
    opacity: 1;
}

.networkerror-main {
    position: fixed;
    left: calc(50% - 185px);
    width: 350px;
    top: calc(50% - 252px);
    height: 505px;
    background: white;
    box-shadow: 0px 2px 5px black;
    text-align: center;
    padding: 12px;
    background: rgba(20, 20, 20);
    color: rgba(190, 190, 190);
    cursor: default;
    transition: top 0.2s ease-in-out;
    text-shadow: none;
}

.networkerror-cover-close .networkerror-main {
    top: calc(50% - 240px);
}

.networkerror-main-header {
    font-size: 24px;
    font-weight: 800;
}

.networkerror-main-cover {
    width: 300px;
    height: 300px;
    background: url(/public/NetworkError.png) no-repeat center center;
    background-size: cover;
    border-radius: 150px;
    margin: auto;
}

.networkerror-description {
    font-weight: 400;
    font-size: 18px;
}

.networkerror-button {
    width: 200px;
    line-height: 20px;
    padding: 12px;
    border-radius: 12px;
    margin: auto;
    background: rgb(220, 202, 176);
    font-size: 14px;
    font-family: Arial;
    color: rgb(30, 30, 30);
    margin-top: 6px;
    cursor: pointer;
    position: relative;
}

.networkerror-button:hover:before {
    content: " ";
    position: absolute;
    top: 0px; left: 0px; width: 100%; height: 100%;
    background: rgba(0, 0, 0, 0.1);
}

.networkerror-button:active:before {
    content: " ";
    position: absolute;
    top: 0px; left: 0px; width: 100%; height: 100%;
    background: rgba(0, 0, 0, 0.2);
}

