.mainscreen {
    font-family: 'Bad Script', cursive;
    position: relative;
    background: url(/public/main-bg.jpg) repeat center center;
    min-height: 100vh;
    color: rgba(255,255,255,0.7);
    text-shadow: 2px 2px #000000;
}

.login {
    display: flex;
    justify-content: flex-end;
    padding: 12px 0px;
    font-size: 18px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    text-shadow: none;
    background: rgba(0, 0, 0, 0.8);
}

.footer {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 12px 0px;
    font-size: 18px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    text-shadow: none;
    opacity: 0.5;
}

.footer a {
    color: white;
}

.loginbutton {
    padding: 0px 24px;
    cursor: pointer;
    text-decoration: underline;
}

.sign:before {
    content:' ';
    position: absolute;
    top: 0; left: 0; width: 100%; height: 100%;
    background: url(/public/main-sign.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: -1;
}

.sign {
    position: absolute;
    width: 200px;
    line-height: 35px;
    padding-right: 30px;
    padding-left: 30px;
    font-weight: 800;
    text-align: center;
    padding-top: 100px;
    cursor: pointer;
    transform-origin: 50% 0px;
    animation: swing 1.5s infinite alternate ease-in-out;
    color: black;
    text-shadow: none;
}

/* sign swinging animation sequence */
@keyframes swing {
  0% { transform: rotate(-1deg) }
  100% { transform: rotate(1deg) }
}

.sign .fa-lock {
    position: absolute;
    bottom: 20px;
    right: 30px;
    font-size: 32px;
    color: rgba(20, 20, 20, 1);
}

@media (max-width: 640px) {
    .sign {
        left: calc(50% - 130px);
        position: relative;
        width: 200px;
        height: 100px; 
        font-size: 24px;
    }
}

@media (min-width: 641px) {
    .sign:nth-child(1) {
        left: 0%; 
        height: 100px; 
        font-size: 24px;
    }
    .sign:nth-child(2) {
        left: calc(50% - 130px); 
        height: 100px; 
        font-size: 24px;
    }
    .sign:nth-child(3) {
        right: 0%; 
        height: 100px; 
        font-size: 24px;
    }
}

.stageimage {
    flex: 0;
    position: relative;
    min-width: 200px;
    height: 200px;
    margin-right: 24px;
}

.stageimage1 {
    position: absolute;
    top: 0px; left: 0px; width: 100%; height: 100%;
    background-size: 100% 100%;
    -webkit-filter: blur(9px);
    filter: blur(9px);
}

.stageimage2 {
    position: absolute;
    top: 0px; left: 0px; width: 100%; height: 100%;
    background-size: 100% 100%;
    border-radius: 50%;
}
