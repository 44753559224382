.story:before {
    content:' ';
    position: fixed;
    top: 0; left: 0; width: 100%; height: 100%;
    background: rgb(35, 35, 35);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: -2;
}

.story {
    font-family: 'Bad Script', cursive;
}

.loader {
  position: fixed;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  perspective: 800px;
}

.loader .inner {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;  
}

.loader .inner.one {
  left: 0%;
  top: 0%;
  animation: rotate-one 1s linear infinite;
  border-bottom: 3px solid #EFEFFA;
}

.loader .inner.two {
  right: 0%;
  top: 0%;
  animation: rotate-two 1s linear infinite;
  border-right: 3px solid #EFEFFA;
}

.loader .inner.three {
  right: 0%;
  bottom: 0%;
  animation: rotate-three 1s linear infinite;
  border-top: 3px solid #EFEFFA;
}

@keyframes rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

@media (min-width: 639px) {
    .centertext {
        position: absolute;
        left: 90px;
        right: 90px;
        top: 100px;
        bottom: 100px;
        color: rgba(220, 202, 176);
        font-size: 18px;
        overflow-y: auto;
        padding-bottom: 20px;
    }
}

@media (max-width: 640px) {
    .centertext {
        position: absolute;
        left: 25px;
        right: 25px;
        top: 25px;
        bottom: 25px;
        color: rgba(220, 202, 176);
        font-size: 18px;
        overflow-y: auto;
        padding-bottom: 20px;
    }
}

@media (min-width: 639px) {
    .border1 {
        position: fixed;
        top: 0; left: 0;
        background: url('/public/1466.jpg') no-repeat -54px -40px;
        background-size: 1250px 937px;
        width: 278px;
        height: 278px;

    }

    .border2 {
        position: fixed;
        top: 0; right: 0;
        background: url('/public/1466.jpg') no-repeat -918px -40px;
        background-size: 1250px 937px;
        width: 278px;
        height: 278px;

    }

    .border3 {
        position: fixed;
        bottom: 0; left: 0;
        background: url('/public/1466.jpg') no-repeat -54px -620px;
        background-size: 1250px 937px;
        width: 278px;
        height: 278px;

    }

    .border4 {
        position: fixed;
        bottom: 0; right: 0;
        background: url('/public/1466.jpg') no-repeat -918px -620px;
        background-size: 1250px 937px;
        width: 278px;
        height: 278px;

    }
}

@media (max-width: 640px) {
    .border1 {
        position: fixed;
        top: 0; left: 0;
        background: url('/public/1466.jpg') no-repeat -13px -10px;
        background-size: 312px 234px;
        width: 69px;
        height: 69px;

    }

    .border2 {
        position: fixed;
        top: 0; right: 0;
        background: url('/public/1466.jpg') no-repeat -229px -10px;
        background-size: 312px 234px;
        width: 69px;
        height: 69px;

    }

    .border3 {
        position: fixed;
        bottom: 0; left: 0;
        background: url('/public/1466.jpg') no-repeat -13px -155px;
        background-size: 312px 234px;
        width: 69px;
        height: 69px;
    }

    .border4 {
        position: fixed;
        bottom: 0; right: 0;
        background: url('/public/1466.jpg') no-repeat -229px -155px;
        background-size: 312px 234px;
        width: 69px;
        height: 69px;
    }
}

.button {
    position: relative;
    cursor: pointer;
    padding: 6px 0px;
}

.button:hover {
    background: rgba(255, 255, 255, 0.1);
}

.buttonbordertop:before {
    content:' ';
    position: absolute;
    top: 0px;
    left: 0px;
    background: url('/public/1466.jpg') no-repeat -672px -1704px;
	background-size: 2500px 1875px;
	width: 28px;
	height: 28px;
}

.buttonbordertop2:before {
    content:' ';
    position: absolute;
    top: 0px;
    right: 0px;
    background: url('/public/1466.jpg') no-repeat -1800px -1704px;
	background-size: 2500px 1875px;
	width: 28px;
	height: 28px;
}

.buttonbordertop:after {
    content:' ';
    position: absolute;
    top: 0px;
    left: 28px;
	width: calc(100% - 56px);
	height: 18px;
    border-bottom: 9px solid rgb(183, 166, 150);
}

.buttonborderbottom:before {
    content:' ';
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: url('/public/1466.jpg') no-repeat -672px -1760px;
	background-size: 2500px 1875px;
	width: 28px;
	height: 28px;
}

.buttonborderbottom:after {
    content:' ';
    position: absolute;
    bottom: 0px;
    left: 28px;
	width: calc(100% - 56px);
	height: 18px;
    border-top: 9px solid rgb(183, 166, 150);
}

.buttonborderbottom2:before {
    content:' ';
    position: absolute;
    bottom: 0px;
    right: 0px;
    background: url('/public/1466.jpg') no-repeat -1800px -1760px;
	background-size: 2500px 1875px;
	width: 28px;
	height: 28px;
}

.controlpanel {
    position: fixed;
    bottom: 0px;
    height: 88px;
    left: 20px;
    right: 20px;
    background: rgba(36, 35, 35, 0.9);
    box-shadow: -2px -2px 6px 6px rgba(50, 50, 50, 0.4);
    transition: all 0.2s ease-in-out 0s;
    border: 2px solid #b7a793;
    border-bottom: none;
    padding: 6px;
    color: #b7a793;
}

.controlpanel-inner {
    display: flex;
    padding-top: 12px;
}

@media (max-width: 640px) {
    .controlpanel {
        height: 108px;
    }
    .controlpanel.hidden {
        bottom: -120px;
    }
    .controlpanel-inner {
        flex-direction: column-reverse;
    }
}

@media (min-width: 639px) {
    .controlpanel.hidden {
        bottom: -100px;
    }
}

.tongue {
    position: absolute;
    left: calc(50% - 50px);
    width: 100px;
    top: -42px;
    height: 40px;
    background: rgba(36, 35, 35, 0.9);
    border-radius: 6px 6px 0px 0px;
    cursor: pointer;
    border: 2px solid #b7a793;
    border-bottom: none;
    text-align: center;
    line-height: 47px;
}

.tongue i {
    color: #b7a793;
    font-size: 24px;
}

.journey {
    width: 155px;
    position: relative;
}

.journey .fa-person-walking {
    position: absolute;
    left: 0px;
    top: -8px;
}

.bounce {
  animation: bounce 10s ease infinite;
}

@keyframes bounce {
    0%, 5%, 12%, 20%, 25% {
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    10% {
        -moz-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    15% {
        -moz-transform: translateY(-15px);
        -ms-transform: translateY(-15px);
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

@media (max-width: 640px) {
    .stageimagedescriptionwrapper {
    }

    .stageimage {
        width: 100%;
        padding-top: 100%;
        margin-bottom: 24px;
        height: 0px;
    }

    .stageimage1 {
        width: 100%;
        padding-top: 100%;
        height: 0px;
    }

    .stageimage2 {
        width: 100%;
        padding-top: 100%;
        height: 0px;
    }
}

@media (min-width: 639px) {
    .stageimagedescriptionwrapper {
        display: flex;
    }
}
